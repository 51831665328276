import axios from "@/axios";

export const CaseCreationStore = {
    namespaced: true,

    state: {
        riskScoreData: null,
    },

    mutations: {
        SET_RISK_SCORE_DATA(state, payload) {
            state.riskScoreData = payload;
        },
    },

    getters: {
        getRiskScoreData: state => state.riskScoreData,
    },

    actions: {
        fetchRiskScore: async({ commit }, payload) => {
            const { case_id, params = {} } = payload;

            try {
                const { data } = await axios.get(`risk-assessment/case/${case_id}`, {
                    params,
                });
                commit('SET_RISK_SCORE_DATA', data);
            } catch (error) {
                console.log("error>>>", error);
            }
        },
    },
};
