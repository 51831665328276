<template>
  <BaseScreen
    class="output-check-screen"
    :loading="isLoading"
    :disabled-submit="disableSubmit"
    @submit="submitScreen"
  >
    <component
      v-if="currentConfig"
      :is="currentConfig.component"
      class="output-check-screen__content relative w-full flex-1 flex flex-col h-full overflow-auto"
      ref="monitoring-tool"
      v-bind="currentConfig.props"
      v-on="currentConfig.listeners"
    />

    <template #footer:before>
      <label class="flex gap-3 items-center cursor-pointer">
        <input
          type="checkbox"
          class="ml-auto h-5 w-5 text-blue-600 pl-2 rounded"
          v-model="noMatch"
        />
        <span class="text-base-content-200">
          No Match Found
        </span>
      </label>
    </template>
	</BaseScreen>
</template>

<script>
// TODO handle record status change
// TODO implement fallback for non-configured check
// add `v-allow:license="monitoringToolLicences[currentConfig.name]"` to the component props if licences should be checked

import axios from "@/axios";
import { mapActions } from "vuex";
import BaseScreen from "./components/BaseScreen.vue";
const LexisBridgerComponent = () => import("@shared/tools/lexis-bridger");
const CorporateRecordsComponent = () => import("@shared/tools/company-identifier");

import {
  CORPORATE_RECORDS_CHECK_ID,
  LEXIS_BRIDGER_CHECK_ID,
  monitoringTools,
  monitoringToolLicences, // TODO check if we need this
} from "@shared/utils/constants";
import { getMonitoringEntities } from "@shared/utils/functions.js";
import { appList } from "@shared/components/form-builder/utils/index.js";

export default {
	components: {
		BaseScreen,
	},
	props: {
    caseEntities: { // TODO provide via provide/inject
      type: Array,
      default: () => [],
    },
    caseEntitiesData: { // TODO provide via provide/inject
      type: Object,
      required: true,
    },
    checkId: {
      type: String,
      required: true,
    },
    checkName: {
      type: String,
      required: true,
    },
    integratedSourceId: {
      type: String,
      required: true,
    },
    app: {
      type: String,
      deafult: appList.CREATE,
    },
    entityChecks: {
      type: Array,
      default: () => [],
    },
	},
  inject: ["sharedCaseState"],
	data() {
		return {
      monitoringToolLicences,
			isLoading: true,
      noMatch: false,
      confirmedRecordList: [],
		}
	},
	async mounted() {
		await this.init();
	},
  computed: {
    caseData() {
      return this.sharedCaseState();
    },
    caseId() {
      return this.caseData.case_id;
    },
    entityId() {
      return this.caseData.entity_id;
    },
    clientId() {
      return this.caseData.client_id;
    },
    disableSubmit() {
      return !(this.confirmedRecordList.length || this.noMatch);
    },

    monitoringEntities() {
      return getMonitoringEntities(this.caseEntities, this.caseEntitiesData);
    },

    toolsConfig() {
      return {
        // TODO enable and handle status change
        [monitoringTools.CORPORATE_RECORDS]: {
          name: monitoringTools.CORPORATE_RECORDS,
          component: CorporateRecordsComponent,
          props: {
            title: this.checkName,
            autorun: this.recordsAutorunConfig(monitoringTools.CORPORATE_RECORDS),
            confirmedRecords: this.confirmedRecordList,
            mainEntity: this.monitoringEntities,
            checkId: this.checkId,
            entityId: this.entityId,
          },
          listeners: {
            recordsConfirmation: this.confirmRecord,
          },
        },
        [monitoringTools.LEXIS_BRIDGER]: {
          name: monitoringTools.LEXIS_BRIDGER,
          component: LexisBridgerComponent,
          props: {
            title: this.checkName,
            autorun: this.recordsAutorunConfig(monitoringTools.LEXIS_BRIDGER),
            confirmedRecords: this.confirmedRecordList,
            mainEntity: this.monitoringEntities,
            checkId: this.checkId,
            entityId: this.entityId,
          },
          listeners: {
            recordsConfirmation: this.confirmRecord,
          },
        },
      }
    },

    currentConfig() {
      const integratedSourceConfig = {
        [CORPORATE_RECORDS_CHECK_ID]: monitoringTools.CORPORATE_RECORDS,
        [LEXIS_BRIDGER_CHECK_ID]: monitoringTools.LEXIS_BRIDGER,
      }

      const currentTool = integratedSourceConfig[this.integratedSourceId] ?? null;

      return currentTool ? this.toolsConfig[currentTool] : null;
    },

    checkData() {
      return this.entityChecks?.find((el) => el.check_id === this.checkId) ?? null;
    },
  },

	methods: {
    ...mapActions({
      getAllFeaturesList: "getAllFeaturesList",
      fetchCasePersonalData: "fetchCasePersonalData"
    }),

    recordsAutorunConfig(type) {
      if (type === monitoringTools.CORPORATE_RECORDS) {
        return {
          headers: {
            "x-tool-name": "company-name",
            "x-entity-id": this.entityId,
          }
        }
      }
      if (type === monitoringTools.LEXIS_BRIDGER) {
        return {
          headers: {
            "x-tool-name": "lexis-bridger-tool",
            "x-entity-id": this.entityId,
          }
        }
      }
    },

		async init() {
      await this.fetchCasePersonalData(this.clientId);
      const initMethods = {
        // initial data for Corporate Records
        [monitoringTools.CORPORATE_RECORDS]: this.getAllFeaturesList,
      }

      this.isLoading = true;
      await initMethods[this.currentConfig.name]?.();
      this.isLoading = false;
		},

    confirmRecord({item, value}) {
      if (value) {
        const alreadyExist = this.confirmedRecordList.some((el) => el.api_all_data?.doc_id === item.api_all_data?.doc_id);
        if (!alreadyExist) this.confirmedRecordList.push(item);
        // TODO fix bug with dabble event trigger
      } else {
        this.confirmedRecordList.splice(this.confirmedRecordList.findIndex((el) => el.api_all_data?.doc_id === item.api_all_data?.doc_id), 1);
      }
    },

    resetRecordsConfirmation() {
      this.confirmedRecordList = [];
    },

    async submitCheck() {
      if (!this.checkData) return;

      const url = `case/${this.caseId}/entity/${this.entityId}/check/${this.checkData.id}`;

      await axios.get(
        url,
        { params: { no_records_found: this.noMatch } }
      );
    },

    async submitScreen() {
      const submitMethods = {
        [monitoringTools.CORPORATE_RECORDS]:  this.submitCheck,
        [monitoringTools.LEXIS_BRIDGER]:  this.submitCheck,
      }

      try {
        await submitMethods[this.currentConfig.name]?.();
        this.$emit('submit');
      } catch (error) {
        this.$emit('error', error);
      }
    },
	},
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/functions.scss";

.output-check-screen {
  &__content {
    min-height: toRem(200px);
  }
}
</style>