<template>
<dropdown
    class="bg-white"
    v-model="model"
    :config="{
        label: 'label',
        trackBy: 'value',
    }"
    :options="confirmationOptions"
    :allow-empty="false"
    show-icon
/>
</template>

<script>
import Dropdown from "@shared/components/dropdown-base/index.vue";
import { confirmationOptions } from "./constants.js";

export default {
    components: {
        Dropdown,
    },
    props: {
        value: Object,
    },
    data() {
        return {
            confirmationOptions,
        }
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
            },
        }
,    }
}
</script>

<style lang="scss" scoped>
</style>